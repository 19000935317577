import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'

const PV = () => (
  <Layout>
    <h1>公式サイトが完成しました！</h1>

<p><br />はじめまして！</p>
<p>
	バーチャルヨットセーラー の uminek.ooo/うみねこです!<br />
	活動開始に先立って、公式サイトをつくりました！<br />
	<br />
	uminek.ooo ドメイン、かわいくないですか！？！？<br />
	ドメインに出来そうな名前を必死に探しました。。。<br />
	Twitterとかで自動でリンクになるので、つぶやいてみてくださいな
	<br />
	このサイトで、各種リンクをまとめたり、動画投稿やその他のお知らせを
	載せていきたいなと思っております。
	</p>
	<p>それでは、またお会いしましょう！！</p>

    <Link to="/">トップへ戻る</Link>
  </Layout>
)

export default PV
